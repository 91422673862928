//
// Sizing
// --------------------------------------------------

// Width
.min-width-20 { min-width: 20px !important; }
.min-width-30 { min-width: 30px !important; }
.min-width-50 { min-width: 50px !important; }
.min-width-75 { min-width: 75px !important; }
.min-width-100 { min-width: 100px !important; }
.min-width-125 { min-width: 125px !important; }
.min-width-150 { min-width: 150px !important; }
.min-width-175 { min-width: 175px !important; }
.min-width-200 { min-width: 200px !important; }
.min-width-300 { min-width: 300px !important; }
.min-width-320 { min-width: 320px !important; }

// Height
.min-height-20 { min-height: 20px !important; }
.min-height-30 { min-height: 30px !important; }
.min-height-50 { min-height: 50px !important; }
.min-height-75 { min-height: 75px !important; }
.min-height-100 { min-height: 100px !important; }
.min-height-125 { min-height: 125px !important; }
.min-height-150 { min-height: 150px !important; }
.min-height-175 { min-height: 175px !important; }
.min-height-200 { min-height: 200px !important; }
.min-height-300 { min-height: 300px !important; }
.min-height-400 { min-height: 400px !important; }
.min-height-500 { min-height: 500px !important; }
