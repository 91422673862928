//
// Ion Range Slider
//
// Overwrite/Extend styles
// --------------------------------------------------

.irs {
    &-line {
        height: 5px;
        background: $body-bg;
        border: none;
        border-radius: 3px;
    }

    &-bar {
        height: 5px;
        border: none;
        background: $brand-primary;

        &-edge {
            height: 5px;
            border: none;
            background: $brand-primary;
            border-radius: 3px 0 0 3px;
        }
    }

    &-slider {
        top: 25px;
        width: 20px;
        height: 20px;
        border: none;
        background: $white;
        border-radius: 50%;
        box-shadow: 0 1px 5px rgba(0,0,0,.35);

        @include hover {
            background: $white;
            box-shadow: 0 1px 5px rgba(0,0,0,.75);
        }
    }

    &-from,
    &-to,
    &-single {
        color: $white;
        font-size: 13px;
        background: $brand-primary;
    }

    &-grid-pol {
        background: $brand-primary;
    }
}
