// Fonts
//@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
// Bootstrap (from node-modules)
//@import '~bootstrap/scss/bootstrap';
// Plugins
@import "../js/plugins/datatables/dataTables.bootstrap4.min";
@import "../js/plugins/select2/select2.min.css";
@import "../js/plugins/select2/select2-bootstrap.min.css";
@import "../js/plugins/sweetalert2/sweetalert2.min.css";
@import "../js/plugins/jquery-timepicker/jquery.timepicker.min.css";
@import "../js/plugins/select2/select2.css";
@import "../js/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css";
// Core
@import "main";

.document_category_row {
  > div {
    padding: 20px;

    align-items: center;
    display: flex;
    flex-direction: column;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;

      i {
        display: block;
        font-size: 80px;
        color: #000;
      }

      &:hover {
        color: #000;

        i {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

.pre-text-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.employee_sheets {
  height: auto;
  max-height: 800px;
  transition: max-height 300ms ease-in-out;
  overflow-y: auto;
  &.closed {
    max-height: 0;
    overflow-y: hidden;
  }
}

.sheet_tr {
  height: auto;
  max-height: 800px;
  transition: max-height 300ms ease-in-out;
  display: table-row;

  &.closed {
    display: none;
  }

  .adjust_sheets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .type_row {
      display: flex;
      span {
        width: 75px;
      }
      select {
        flex: 1;
      }
    }
  }
}
.timesheet_control_row {
  margin-top: 12px;
  &:first-child {
    margin-top: 0;
  }
}
.transaction-table {
  input {
    width: 100px;
  }
  select {
    width: 160px;
  }
}