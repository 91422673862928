//
// Display
// --------------------------------------------------

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-auto {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.opacity-0 {
    opacity: 0;
}

.opacity-1 {
    opacity: 1;
}
