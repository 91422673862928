//
// Custom
//
// Add your own styles or override existing ones
// --------------------------------------------------
.ui-timepicker-standard {
	z-index: 9999 !important;
}

.table thead th {
	font-size: 12px;
	vertical-align: top;
}

.table td {
	font-size: 13px;
}

.js-swal-destroy, .pointer {
	cursor:pointer;
}

table.schedule-table {
	tr {
		td.redDay button {
			color: red;
		}
	}
}

.datepicker .day.special {
	color: red;
	font-weight: bold;
}
.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
	background-color: $brand-primary;
	border-color: $brand-primary;
	color: #fff;
}

.filter_employee_select, .filter_client_select {
	width: 100%;
}

.btn-group-xs > .btn, .btn-xs {
	padding: 0.2rem;
	font-size: 0.875rem;
	border-radius: .2rem;
	max-height: 1.6rem;
}

.beginning-with-capital {
	text-transform: capitalize;
	option {
		text-transform: capitalize;
	}
}

.nav-tabs-block .nav-link.active, .nav-tabs-block .nav-item.show .nav-link {
	color: $brand-primary;
	background-color: #fff;
	border-color: transparent;
}

.model-content {
	overflow-y: auto;
}
