//
// Spacing
// --------------------------------------------------

@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $lengths in $space-breaks {
        $length-x: map-get($lengths, x);
        $length-y: map-get($lengths, y);

        .#{$abbrev}-#{$size}  { #{$prop}:        $length-y $length-x !important; }

        .#{$abbrev}t-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-top:    $length-y !important;
        }

        .#{$abbrev}r-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-right:  $length-x !important;
        }

        .#{$abbrev}b-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-bottom: $length-y !important;
        }

        .#{$abbrev}l-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-left:   $length-x !important;
        }
    }
}

.push,
.items-push > div {
    margin-bottom: $space-base;
}
